import classNames from 'classnames';
import React, { useContext } from 'react';
import { RicosNodeContext, RicosNodeContextProvider } from 'ricos-context';
import PropTypes from 'prop-types';

var styles$1 = {"cell":"s7Hwa","header":"HQ3oI","selected":"X3--q","editing":"kuTgy","singleSelection":"E8v3Z","hideLastBlock":"OCbyW","editorWrapper":"GH6w8","viewerWrapper":"_1IkvK","hideFirstBlock":"pZ3L5","editor":"aVSXd","edit":"fWYxl","view":"lk-PE","editingToolbarWrapper":"hSkuF","selectAll":"ejU6m","disableSelection":"Xj8yb"};

var styles = {"borderT":"p6Dfz","borderB":"TcaSf","borderL":"VFt0C","borderR":"HIpj6"};

var CellBorders = function CellBorders(_ref) {
  var borders = _ref.borders;
  return [(borders == null ? void 0 : borders.top) && /* @__PURE__ */React.createElement("div", {
    key: "top",
    style: {
      background: borders.top
    },
    className: styles.borderT
  }), (borders == null ? void 0 : borders.bottom) && /* @__PURE__ */React.createElement("div", {
    key: "bottom",
    style: {
      background: borders.bottom
    },
    className: styles.borderB
  }), (borders == null ? void 0 : borders.right) && /* @__PURE__ */React.createElement("div", {
    key: "right",
    style: {
      background: borders.right
    },
    className: styles.borderR
  }), (borders == null ? void 0 : borders.left) && /* @__PURE__ */React.createElement("div", {
    key: "left",
    style: {
      background: borders.left
    },
    className: styles.borderL
  })];
};
CellBorders.propTypes = {
  borders: PropTypes.object
};
var CellBorders$1 = CellBorders;

function _createForOfIteratorHelperLoose(r, e) { var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (t) return (t = t.call(r)).next.bind(t); if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) { t && (r = t); var o = 0; return function () { return o >= r.length ? { done: !0 } : { done: !1, value: r[o++] }; }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(r, a) { if (r) { if ("string" == typeof r) return _arrayLikeToArray(r, a); var t = {}.toString.call(r).slice(8, -1); return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0; } }
function _arrayLikeToArray(r, a) { (null == a || a > r.length) && (a = r.length); for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e]; return n; }
var __defProp = Object.defineProperty;
var __getOwnPropSymbols = Object.getOwnPropertySymbols;
var __hasOwnProp = Object.prototype.hasOwnProperty;
var __propIsEnum = Object.prototype.propertyIsEnumerable;
var __defNormalProp = function __defNormalProp(obj, key, value) {
  return key in obj ? __defProp(obj, key, {
    enumerable: true,
    configurable: true,
    writable: true,
    value: value
  }) : obj[key] = value;
};
var __spreadValues = function __spreadValues(a, b) {
  for (var prop in b || (b = {})) if (__hasOwnProp.call(b, prop)) __defNormalProp(a, prop, b[prop]);
  if (__getOwnPropSymbols) {
    for (var _iterator = _createForOfIteratorHelperLoose(__getOwnPropSymbols(b)), _step; !(_step = _iterator()).done;) {
      var prop = _step.value;
      if (__propIsEnum.call(b, prop)) __defNormalProp(a, prop, b[prop]);
    }
  }
  return a;
};
var __objRest = function __objRest(source, exclude) {
  var target = {};
  for (var prop in source) if (__hasOwnProp.call(source, prop) && exclude.indexOf(prop) < 0) target[prop] = source[prop];
  if (source != null && __getOwnPropSymbols) {
    for (var _iterator2 = _createForOfIteratorHelperLoose(__getOwnPropSymbols(source)), _step2; !(_step2 = _iterator2()).done;) {
      var prop = _step2.value;
      if (exclude.indexOf(prop) < 0 && __propIsEnum.call(source, prop)) target[prop] = source[prop];
    }
  }
  return target;
};
var TableCell = function TableCell(_ref) {
  var node = _ref.node,
    nodeIndex = _ref.nodeIndex,
    RicosNodesRenderer = _ref.RicosNodesRenderer;
  var _a, _b, _c;
  var _useContext = useContext(RicosNodeContext),
    parentNode = _useContext.parentNode,
    parentIndex = _useContext.parentIndex;
  var _ref2 = (node == null ? void 0 : node.tableCellData) || {},
    borderColors = _ref2.borderColors,
    cellStyle = _ref2.cellStyle;
  var isHeaderCell = parentIndex === 0 && ((_a = parentNode == null ? void 0 : parentNode.tableData) == null ? void 0 : _a.rowHeader) || nodeIndex === 0 && ((_b = parentNode == null ? void 0 : parentNode.tableData) == null ? void 0 : _b.columnHeader);
  var Tag = isHeaderCell ? "th" : "td";
  var style = {};
  (cellStyle == null ? void 0 : cellStyle.backgroundColor) && (style.backgroundColor = cellStyle.backgroundColor);
  (cellStyle == null ? void 0 : cellStyle.verticalAlignment) && (style.verticalAlign = cellStyle.verticalAlignment.toLowerCase());
  return /* @__PURE__ */React.createElement(Tag, {
    "data-hook": "table-plugin-cell",
    className: classNames(styles$1.cell, isHeaderCell && styles$1.header),
    style: style
  }, /* @__PURE__ */React.createElement("div", {
    className: classNames(styles$1.editor, styles$1.view)
  }, /* @__PURE__ */React.createElement(RicosNodeContextProvider, {
    parentNode: parentNode,
    parentIndex: nodeIndex
  }, /* @__PURE__ */React.createElement(RicosNodesRenderer, {
    nodes: (_c = node.nodes) != null ? _c : [],
    shouldParagraphApplyTextStyle: true
  }))), CellBorders$1({
    borders: borderColors
  }));
};
var TableCellRenderer = function TableCellRenderer(_a) {
  var _b = _a,
    node = _b.node,
    props = __objRest(_b, ["node"]);
  return /* @__PURE__ */React.createElement(TableCell, __spreadValues({
    key: node.id,
    node: node
  }, props));
};

export { TableCellRenderer as default };

import 'wix-rich-content-plugin-table/dist/loadable/viewer/es/styles.viewer.min.global.css'